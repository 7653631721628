import { __assign } from "tslib";
import * as React from 'react';
import { FfpAccountFieldEnum } from '@websky/graphql';
import CreateAccountBase from '@websky/core/src/Loyalty/components/LoyaltyStub/LoyaltyConnect/CreateAccount/CreateAccount';
var CreateAccount = function (props) {
    var fieldGroup = [
        [FfpAccountFieldEnum.LatinFirstName],
        [FfpAccountFieldEnum.LatinMiddleName],
        [FfpAccountFieldEnum.LatinLastName],
        [FfpAccountFieldEnum.FirstName],
        [FfpAccountFieldEnum.MiddleName],
        [FfpAccountFieldEnum.LastName],
        [FfpAccountFieldEnum.BirthDate, FfpAccountFieldEnum.Gender],
        [FfpAccountFieldEnum.Email],
        [FfpAccountFieldEnum.Country, FfpAccountFieldEnum.Phone],
        [FfpAccountFieldEnum.City]
    ];
    return React.createElement(CreateAccountBase, __assign({}, props, { customFieldsGroup: fieldGroup }));
};
export default CreateAccount;
