import * as React from 'react';
import { initI18n } from '../../../../core/src/utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../core/src/MilesRecovery/theme';
var MilesRecoveryMessage = function (success) {
    initI18n('MilesRecovery');
    var theme = useTheme().MilesRecoveryStyles;
    var t = useTranslation('MilesRecovery').t;
    if (!!success.length) {
        return (React.createElement("div", null,
            React.createElement("div", { className: theme.about }, t('The application for mileage restoration has been successfully registered')),
            React.createElement("div", { className: theme.info }, t('Please note that miles are credited to your personal account after declared data')),
            React.createElement("div", { className: theme.info, dangerouslySetInnerHTML: {
                    __html: t('According to paragraph 4.4.2. of the {{link}} \'Flying easily! Processing time of the application can be up to 45 days.', {
                        link: "<a href=\"https://www.nordstar.ru/bonus-programs/rules/\" target=\"_blank\" class=".concat(theme.link, ">\n        ").concat(t('program rules'), "\n      </a>")
                    })
                } })));
    }
};
export default MilesRecoveryMessage;
