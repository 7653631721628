import { __assign, __rest } from "tslib";
import React from 'react';
import { useSelector } from 'react-redux';
import CheckoutAdditionalService from '@websky/core/src/CheckoutAdditionalService/CheckoutAdditionalService/CheckoutAdditionalService';
import { getPassengerStateCompatible } from '@websky/core/src/Checkout/store/selectedServices/selectors';
import { getSeatMap } from '@websky/core/src/Checkout/store/order/selectors';
var SeatsAdditionalService = function (_a) {
    var showAddButton = _a.showAddButton, isSelected = _a.isSelected, props = __rest(_a, ["showAddButton", "isSelected"]);
    var passengers = useSelector(getPassengerStateCompatible);
    var seatMap = useSelector(getSeatMap);
    var hasAvailableSeatsInSeatMap = seatMap.segments.some(function (segment) { var _a; return (_a = segment.decks) === null || _a === void 0 ? void 0 : _a.some(function (deck) { var _a; return (_a = deck.rows) === null || _a === void 0 ? void 0 : _a.some(function (row) { var _a; return (_a = row.seats) === null || _a === void 0 ? void 0 : _a.length; }); }); });
    var passengersHasSelectedSeats = passengers.some(function (passenger) { var _a; return (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length; });
    return (React.createElement(CheckoutAdditionalService, __assign({}, props, { showAddButton: showAddButton || (!passengersHasSelectedSeats && hasAvailableSeatsInSeatMap), isSelected: isSelected })));
};
export default SeatsAdditionalService;
