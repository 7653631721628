import * as React from 'react';
import { LocationType } from '@websky/core/src/types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import css from './Value.css';
var Value = function (_a) {
    var _b;
    var isOpen = _a.isOpen, isLoading = _a.isLoading, location = _a.location, inputValue = _a.inputValue, type = _a.type, withMultiCity = _a.withMultiCity, onClick = _a.onClick;
    var t = useTranslation('SearchForm').t;
    return (React.createElement("div", { className: cn(css.value, (_b = {}, _b[css.value_open] = isOpen, _b)), onClick: onClick },
        React.createElement("div", { className: css.label }, t(type === LocationType.Departure ? 'From' : 'To')),
        !isOpen && !location && (React.createElement("div", { className: css.placeholder }, t(type === LocationType.Departure ? 'Departure airport or city' : 'Arrival airport or city'))),
        !isOpen && location && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.iata }, location.iata),
            React.createElement("div", { className: css.city }, location.name)))));
};
export default Value;
