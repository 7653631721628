import React from 'react';
import { useTheme } from '@websky/core/src/theme';
var Header = function (_a) {
    var header = _a.header, icon = _a.icon, isSelected = _a.isSelected;
    var theme = useTheme('CheckoutAdditionalService').CheckoutAdditionalService;
    return (React.createElement("div", { className: theme.header },
        React.createElement("div", { className: theme.header__text },
            isSelected && icon && React.createElement("div", { className: theme.icon }, icon),
            header)));
};
export default Header;
