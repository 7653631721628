import { __assign } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@websky/core/src/theme';
import CheckoutAdditionalServiceBase from '@websky/core/src/CheckoutAdditionalService';
import Button from '@websky/core/src/Button';
import Money from '@websky/core/src/Money';
import css from './CheckoutAdditionalService.css';
var CheckoutAdditionalService = function (props) {
    var theme = useTheme('CheckoutAdditionalService').CheckoutAdditionalService;
    var t = useTranslation('CheckoutAdditionalService').t;
    var renderControls = React.useCallback(function (_a) {
        var buttons = _a.buttons, addButtonText = _a.addButtonText, showAddButton = _a.showAddButton, isSelected = _a.isSelected, isLoading = _a.isLoading, addClick = _a.addClick, priceTotal = _a.priceTotal, priceFrom = _a.priceFrom;
        return (React.createElement("div", { className: theme.buttons },
            React.createElement("div", { className: css.price },
                isSelected && priceTotal && React.createElement(Money, { money: priceTotal }),
                !isSelected && priceFrom && (React.createElement(React.Fragment, null,
                    t('from'),
                    " ",
                    React.createElement(Money, { money: priceFrom })))),
            isSelected &&
                (buttons === null || buttons === void 0 ? void 0 : buttons.length) &&
                buttons.map(function (button) { return (React.createElement(Button, { key: button.text, isLoading: button.isLoading, onClick: function () { return button.action(); }, variant: "text", size: "common" }, button.text)); }),
            !isSelected && showAddButton !== false && (React.createElement(Button, { isLoading: isLoading, variant: "outline", className: theme.add, onClick: addClick }, addButtonText ? t(addButtonText) : t('Add')))));
    }, [props.priceFrom, props.priceTotal]);
    return React.createElement(CheckoutAdditionalServiceBase, __assign({}, props, { renderControls: renderControls }));
};
export default { CheckoutAdditionalService: CheckoutAdditionalService };
