import { __assign } from "tslib";
import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarComponent from '@websky/core/src/Results/components/Results/Calendar/Calendar';
import css from './Calendar.css';
var Calendar = function (props) {
    var t = useTranslation('Results').t;
    return (React.createElement(CalendarComponent, __assign({}, props, { renderTitle: function (_a) {
            var legNumber = _a.legNumber, departureCity = _a.departureCity, arrivalCity = _a.arrivalCity;
            return (React.createElement("div", { className: css.title },
                React.createElement("div", { className: css.number }, legNumber),
                React.createElement("div", { className: css.text },
                    React.createElement("span", null, t('Select flight')),
                    "\u00A0",
                    React.createElement("span", { className: css.cities },
                        departureCity,
                        " \u2013 ",
                        arrivalCity))));
        } })));
};
export default Calendar;
