import { __assign, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from '../../../../utils';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
var calendarIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.4446 4.07401H15.1853C16.0002 4.07401 16.6668 4.74068 16.6668 5.55549V15.9259C16.6668 16.7407 16.0002 17.4073 15.1853 17.4073H4.81498C3.99276 17.4073 3.3335 16.7407 3.3335 15.9259L3.3409 5.55549C3.3409 4.74068 3.99276 4.07401 4.81498 4.07401H5.55572V2.59253H7.0372V4.07401H12.9631V2.59253H14.4446V4.07401ZM4.81498 15.9259H15.1853V7.77771H4.81498V15.9259Z", fill: "currentColor" })));
var OtherDates = function (_a) {
    var _b, _c, _d;
    var startNewSearch = _a.startNewSearch, searchParameters = _a.searchParameters, alternativePrices = _a.alternativePrices;
    var css = useTheme('Results').OtherDates;
    var t = useTranslation('Results').t;
    var _e = __read(useState(null), 2), minAlternativePrice = _e[0], setMinAlternativePrice = _e[1];
    var calendarDaysCount = (_b = useConfig()) === null || _b === void 0 ? void 0 : _b.Engine.calendarDaysCount;
    var daysOffset = Math.floor(calendarDaysCount / 2);
    var currentDate = (_d = (_c = searchParameters === null || searchParameters === void 0 ? void 0 : searchParameters.segments) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.date;
    var priceToCompareWith = React.useMemo(function () { var _a; return (_a = alternativePrices === null || alternativePrices === void 0 ? void 0 : alternativePrices.find(function (price) { return price.date === currentDate; })) === null || _a === void 0 ? void 0 : _a.price; }, [alternativePrices, currentDate]);
    useEffect(function () {
        if (priceToCompareWith) {
            var dates = alternativePrices;
            var minAlternativePrice_1 = null;
            if (dates) {
                for (var i = 1; i <= daysOffset; i++) {
                    var leftDate = dates[daysOffset - i];
                    var rightDate = dates[i + daysOffset];
                    if (leftDate.price &&
                        (!minAlternativePrice_1 ||
                            (leftDate.price.amount < priceToCompareWith.amount &&
                                leftDate.price.amount < minAlternativePrice_1.price.amount))) {
                        minAlternativePrice_1 = leftDate;
                    }
                    if (rightDate.price &&
                        (!minAlternativePrice_1 ||
                            (rightDate.price.amount < priceToCompareWith.amount &&
                                rightDate.price.amount < minAlternativePrice_1.price.amount))) {
                        minAlternativePrice_1 = rightDate;
                    }
                }
                if (dates &&
                    ((minAlternativePrice_1 && minAlternativePrice_1.price.amount === priceToCompareWith.amount) ||
                        (minAlternativePrice_1 &&
                            priceToCompareWith.amount * 0.1 >
                                priceToCompareWith.amount - minAlternativePrice_1.price.amount))) {
                    setMinAlternativePrice(null);
                }
                else {
                    if (minAlternativePrice_1) {
                        setMinAlternativePrice({
                            date: minAlternativePrice_1.date,
                            profit: {
                                amount: priceToCompareWith.amount - minAlternativePrice_1.price.amount,
                                currency: minAlternativePrice_1.price.currency
                            }
                        });
                    }
                }
            }
        }
    }, [alternativePrices, priceToCompareWith]);
    var onClick = useCallback(function () {
        var newSearchParameters = __assign({}, searchParameters);
        newSearchParameters.segments[0].date = minAlternativePrice.date;
        startNewSearch(newSearchParameters);
    }, [searchParameters, minAlternativePrice]);
    return minAlternativePrice ? (React.createElement("div", { className: css.suggestions },
        React.createElement("div", { className: css.container, onClick: onClick },
            calendarIcon,
            React.createElement("div", { className: css.text },
                t('Depart on'),
                " ",
                format(minAlternativePrice.date, 'd MMMM'),
                ": ",
                t('save up to'),
                "\u00A0",
                React.createElement(Money, { money: minAlternativePrice.profit, moneyClassName: css.money }))))) : null;
};
export default OtherDates;
