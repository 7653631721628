import { __assign, __read, __rest, __spreadArray } from "tslib";
import * as React from 'react';
import { addMonths, differenceInCalendarDays, endOfMonth, format, startOfMonth } from 'date-fns';
import { PriceGraph as PriceGraphComponent, RouteType } from '../../../../../PriceGraph';
import DummyPriceGraph from '../DummyPriceGraph/DummyPriceGraph';
import { sortSegments } from '../utils';
import { API_DATE_FORMAT } from '../../../../../utils';
import EmptyDatesOverlay from '../../../../../SearchFormDatepicker/components/EmptyDatesOverlay/EmptyDatesOverlay';
import { useMinPricesQuery } from '@websky/graphql';
var PriceGraph = function (_a) {
    var searchParameters = _a.searchParameters, legId = _a.legId, startNewSearch = _a.startNewSearch, onClose = _a.onClose;
    var segments = searchParameters.segments, promotionCode = searchParameters.promotionCode, restSearchParameters = __rest(searchParameters, ["segments", "promotionCode"]);
    var centralDate = new Date(searchParameters.segments[legId].date);
    var startDate = startOfMonth(new Date());
    // Remove all segments from request except the current one.
    var request = __assign(__assign({}, restSearchParameters), { segments: [__assign(__assign({}, segments[legId]), { date: format(startDate, API_DATE_FORMAT) })] });
    var onDateClick = function (date) {
        var segments = __spreadArray([], __read(searchParameters.segments), false);
        segments[legId] = __assign(__assign({}, segments[legId]), { date: format(date, API_DATE_FORMAT) });
        var segmentsWithRightOrder = sortSegments(legId, date, segments);
        startNewSearch(__assign(__assign({}, searchParameters), { segments: segmentsWithRightOrder }));
    };
    var _b = useMinPricesQuery({
        variables: {
            params: __assign(__assign({}, request), { daysCount: differenceInCalendarDays(endOfMonth(addMonths(centralDate, 6)), startDate) })
        },
        context: {
            currency: request.currency
        }
    }), data = _b.data, loading = _b.loading;
    if (loading) {
        return React.createElement(DummyPriceGraph, null);
    }
    if (!data || !data.FlightsMinPricesInPeriod || !data.FlightsMinPricesInPeriod.datesWithLowestPrices.length) {
        return React.createElement(EmptyDatesOverlay, { onClick: onClose });
    }
    var prices = {};
    data.FlightsMinPricesInPeriod.datesWithLowestPrices.forEach(function (item) {
        prices[new Date(item.date).toISOString()] = {
            price: item.price,
            hasFlight: false // we don't need this property in priceGraph
        };
    });
    return (React.createElement(PriceGraphComponent, { openDate: centralDate, outDate: legId > 0 ? new Date(searchParameters.segments[legId - 1].date) : null, routeType: RouteType.Outbound, onSelect: onDateClick, prices: prices }));
};
export default PriceGraph;
