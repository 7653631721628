import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
var SureModal = function (_a) {
    var className = _a.className, title = _a.title, text = _a.text, footer = _a.footer;
    var css = useTheme('FfpInfoNotice').SureModal;
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: css.title }, title),
        text && React.createElement("div", { className: css.text }, text),
        React.createElement("div", { className: css.footer }, footer)));
};
export default SureModal;
