import React from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from '@websky/core/src/context';
import Link from '@websky/core/src/Link';
var InfoLinksLabel = function () {
    var t = useTranslation().t;
    var companyInfo = useConfig().global.companyInfo;
    var hasPrivacyPolicy = companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.privacyPolicy;
    return (React.createElement("div", null,
        t('Checkout:I accept the'),
        ' ',
        hasPrivacyPolicy && (React.createElement(React.Fragment, null,
            React.createElement(Link, { target: '_blank', action: companyInfo.privacyPolicy }, t('Checkout:processing of my personal data')),
            "\u00A0",
            t('Checkout:and'),
            "\u00A0",
            React.createElement(Link, { action: function () { } },
                React.createElement("span", { "data-type": "termsOfTariff" },
                    t('Checkout:terms of tariff'),
                    "."))))));
};
export default InfoLinksLabel;
